<template>
  <div>
   <img v-if="currentTabIdx===0" src="../assets/bg.jpg" class="bg_img" alt="">
    <img v-else src="../assets/header.jpg" class="header_bg_img" alt="">

    <div class="home_body">
      <div class="header" :class="isPhone?'phone_tab_font':'pc_tab_font'">
        <img v-if="currentTabIdx===0" src="../assets/sel_logo.png" class="logo_img" @click="clickTab(0)" alt=""/>
        <img v-else src="../assets/logo.png" class="logo_img" @click="clickTab(0)" alt=""/>
<!--        <p :class="currentTabIdx===0?'sel_tab_txt':'tab_txt'"  class="ff" @click="clickTab(0)">AUTSUN</p>-->
        <p :class="currentTabIdx===1?'sel_tab_txt':'tab_txt'" @click="clickTab(1)">关于我们</p>
        <p :class="currentTabIdx===2?'sel_tab_txt':'tab_txt'" @click="clickTab(2)">业务能力</p>
        <p :class="currentTabIdx===3?'sel_tab_txt':'tab_txt'" @click="clickTab(3)">联系我们</p>
      </div>

      <div v-if="currentTabIdx===0" style="display: flex;justify-content: center;height: 70vh;align-items: center">
        <Transition name="slide-fade">
          <p v-if="show" class="home_purpose_txt" :style="isPhone?'font-size:20px':'font-size:50px'">{{swiperTexts[swiperIdx]}}</p>
        </Transition>
      </div>

      <div v-if="currentTabIdx===1" :style="isPhone?'margin:20vh 30px 30px 30px;font-size:12px':'margin-top:30vh;margin-left:20%;margin-right:20%'">
        <p class="left_align">AUTSUN 是一家总部在中国上海的一家，独立的，专注的医疗健康咨询公司。</p>
        <p class="left_align">AUTSUN 于许多跨国及本土企业紧密合作，提供医疗技术咨询，健康行业研究， 和医疗市场调研服务。</p>
        <p class="left_align">AUTSUN 秉承着乐观，简单，专注的价值观，为客户提供专业的可行的解决方 案，可靠的研究报告以及精准的调研数据。</p>
        <p class="left_align">AUTSUN 希望以数字化方式驱动自我，推动医疗健康行业的发展。</p>
      </div>

      <div v-if="currentTabIdx===2"  :style="isPhone?'margin:20vh 30px 30px 30px;font-size:12px':'margin-top:30vh;margin-left:20%;margin-right:20%'">
        <div  class="left_align" style="display: flex;flex-direction: row">
          <p style="width: 30%;margin-right: 10%;flex-shrink: 0;">医疗技术咨询</p>
          <p  class="left_align" >与医疗技术公司通力合作，为患者的整个医疗过程开发提供解决方案。</p>
        </div>
        <div  class="left_align" style="display: flex;flex-direction: row">
          <p style="width: 30%;margin-right: 10%;;flex-shrink: 0">健康行业研究</p>
          <p  class="left_align" >与客户和医护人员合作，以数字为驱动，以技术为基础，从需求到应 用，再到可行性方案。为客户提供可信的分析及趋势研究。</p>
        </div>
        <div  class="left_align" style="display: flex;flex-direction: row">
          <p style="width: 30%;margin-right: 10%;;flex-shrink: 0">医疗市场调研</p>
          <p  class="left_align" >根据客户要求，筛选特定人群，通过大范围走访，深入访谈的形式，提供高质量的调研报告。为客户的战略项目提供精准的方向</p>
        </div>

      </div>

      <div v-if="currentTabIdx===3" :style="isPhone?'margin:24vh 30px 30px 30px;font-size:12px':'margin-top:30vh;margin-left:20%;margin-right:20%'">
        <p class="left_align">上海卓茹睿道科技发展有限公司</p>
        <p class="left_align">铜仁路 299 号 SOHO 东海广场 21 楼 2122 室</p>
        <p class="left_align">静安区 上海市 中国</p>
        <p class="left_align">+8613818975607</p>
      </div>
    </div>

    <div v-if="isPhone"  class="bottom_info_phone">
      <div>AUTSUN</div>
      <div>铜仁路 299 号 SOHO 东海广场 21 楼 2122 室</div>
      <div>静安区 上海市 中国</div>
      <div>上海卓茹睿道科技发展有限公司</div>
      <div>Project@autsun.cn</div>
      <div>备案号 </div>
      <div style="margin-bottom: 10px">沪ICP备2023039869号</div>

    </div>

    <div v-else class="bottom_info">
      <p>AUTSUN</p>
      <div>
        <div class="left_align">铜仁路 299 号 SOHO 东海广场 21 楼 2122 室</div>
        <div class="left_align">静安区 上海市 中国</div>
      </div>
      <div>
        <div class="left_align">上海卓茹睿道科技发展有限公司</div>
        <div class="left_align">Project@autsun.cn</div>
      </div>
      <div>
        <div class="left_align">备案号</div>
        <div class="left_align">沪ICP备2023039869号</div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data(){
    return{
      currentTabIdx:0,
      swiperTexts:["我们的愿景：让健康更容易","我们的使命：数字驱动健康","我们的价值观：乐观 简单 专注"],
      swiperIdx:0,
      timer:undefined,
      show:true,
      isPhone:false
    }
  },

  mounted() {
    let windowWidth = document.body.offsetWidth || document.documentElement.clientWidth;
    if(windowWidth < 600){
      this.isPhone = true
    }else{
      this.isPhone = false
    }

    this.timer = setInterval(()=>{
      let newIdx = this.swiperIdx+1
      if(newIdx > 2){
       newIdx = newIdx % 3
      }
      this.swiperIdx = newIdx
      this.show = !this.show;

        },3000)
  },

  methods:{
    clickTab(type){
      this.currentTabIdx = type
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home_body{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bg_img{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  object-fit: cover;
}

.header_bg_img{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 30vh;
  object-fit: cover;
  z-index: -1;
}

.header{
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-left: 5%;
  margin-right: 10%;
  justify-content: space-around;
  font-size: 24px;
}

.logo_img{
  width:20%;
  max-width: 200px;
  min-width: 60px;
  object-fit: scale-down;
}

.tab_txt{
  white-space: nowrap;
  color: #222222;
  font-weight: bold;
}

.sel_tab_txt{
  white-space: nowrap;
  color: #e87b0d;
  font-weight: bold;
}

.pc_tab_font{
  font-size: 24px;
}

.phone_tab_font{
  font-size: 14px;
}


.left_align{
  text-align: start;
}

.home_purpose_txt{
  color: white;
  font-weight: bold;
}

/*
  进入和离开动画可以使用不同
  持续时间和速度曲线。
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.3, 0.4, 1);
}

.slide-fade-enter-from{
  transform: translateX(40px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-40px);
  opacity: 0;
}

  .bottom_info{
    position: fixed;
    left: 0;
    bottom: 0;
    margin: 3% 5%;
    display: flex;
    justify-content: space-around;
    width: 90%;
    font-size: 10px;
  }

  .bottom_info_phone{
    position: fixed;
    left: 0;
    bottom: 0;
    margin: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 8px;
  }

  .ff{
    font-family:Academy Engraved LET;
  }




</style>
